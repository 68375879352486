// Также привязываем к onclick на кнопку additional-service-save, чтобы сохранить данные и отправить запрос на функцию, вызвать метод saveAdditionalService
const saveBtn = document.getElementById('additional-service-save');
let isServiceAdding = false;
// Собираем данные из таблицы
const table = document.getElementById('additional-service-table');

// Модальное окно
const modal = document.getElementById('add-service-modal');

const closeModalBtn = modal.querySelector('.modal__close');

// Перепривязываем событие на закрытие модального окна
closeModalBtn.onclick = async () => {
    modal.classList.add('hidden');
    await toggleMainMenu('show'); // Скрываем все кроме главного меню
};

saveBtn.onclick = async () => {
    if (isServiceAdding) {
        console.warn("Пожалуйста, подождите пока предыдущий запрос на обновление доп.услуги выполнится");
        return;
    }
    // Собираем данные из таблицы
    const tbody = table.querySelector('tbody');
    const rows = tbody.querySelectorAll('tr');

    const data = {
        bookingId: currentBookingData.bookingId,
        serviceId: additionalServiceSelect.getSelected()[0],
        dates: {},
    };

    [...rows].forEach(row => {
        const date = row.querySelector('.date').value;
        const quantity = row.querySelector('.quantity').value;
        const sum = row.querySelector('.sum').value;
        data.dates[date] = {quantity, sum};
    });

    // Начинаем процесс запроса
    isServiceAdding = true;
    mainSpinner.classList.remove('hidden');
    try {
        // Отправляем запрос на сохранение доп.услуги
        console.log(data);

        const response = await saveAdditionalService(currentBookingData.id, currentBookingData.hotelName, data);
        console.log(response);

        if (response.result === 'error') {
            throw new Error(response.message);
        }
        showSuccessPopup('Дополнительная услуга успешно добавлена', currentBookingData.bookingId);
    } catch(error) {
        console.error(error);
        showErrorPopup(`Не удалось сохранить доп.услугу: ${error.message}`, currentBookingData.bookingId);
    } finally {
        // Если успешно, то закрываем модальное окно и обновляем данные по бронированию
        modal.classList.add('hidden');
        mainSpinner.classList.add('hidden');
        isServiceAdding = false;
    }
}

// Добавляем обработчик событий для добавление доп.услуг в бронь (реагируем на инпут)
table.addEventListener('input', ({target}) => {
    if (!target.classList.contains('quantity')) {
        return;
    }
    const tr = target.closest('tr');
    // Проверяем выбрана ли какая-то услуга
    const selectedServiceId = additionalServiceSelect.getSelected()[0];
    if (!selectedServiceId) {
        return;
    }

    // Получаем стоимость цену той услуги которая выбрана
    const servicePrice = currentBookingData.allowedToAddAdditionalService.find(service => +service.id === +selectedServiceId).price;

    // Получаем поле суммы в той же строке
    const sumField = tr.querySelector('.sum');

    // Вычисляем общую стоимость
    // Обновляем поле суммы общей стоимостью
    sumField.value = +target.value * +servicePrice;
});
